<template>
  <v-container>
    <Snackbar :snackbar="snackbar" :snackbartext="snackbartext" @setflasesnackbar="snackbar = false"> </Snackbar>

    <v-data-table
      :headers="headers"
      :items="userdomains"
      :search="search"
      :loading="notloaded"
      loading-text="Loading..."
      sort-by="domain"
      class="elevation-1"
      :expanded.sync="expanded"
      :single-expand="singleExpand"
      :show-expand="expandflag"
      :footer-props="{ prevIcon: 'mdi-arrow-left', nextIcon: 'mdi-arrow-right' }"
      item-key="domain"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      :items-per-page="roles.itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Domain Management</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
          <v-dialog v-model="dialog" max-width="1000px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2 mx-4" v-bind="attrs" v-on="on">ADD DOMAIN</v-btn>
              <!-- <v-btn  icon  v-bind="attrs" v-on="on" :to="backmenu"><v-icon class="blue">arrow_back</v-icon></v-btn>-->
            </template>

            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <Alertmsg v-if="alertflag" :alertmsg="alertmsg" @setflasealert="alertflag = false"> </Alertmsg>
                  <v-row>
                    <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                      <v-text-field v-model="editedItem.domain" :rules="nameRules" label="Domain"></v-text-field>
                    </v-col>
                    <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                      <v-text-field
                        v-model="editedItem.superadminemail"
                        :rules="emailRules"
                        label="Super Admin"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                      <v-text-field
                        v-model="editedItem.hradminemail"
                        :rules="emailRules"
                        label="HR Admin"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                      <v-text-field
                        v-model="editedItem.sysadminemail"
                        :rules="emailRules"
                        label="SYS Admin"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                      <v-text-field v-model="editedItem.pfratio" :rules="numberinput" label="PF Ratio"></v-text-field>
                    </v-col>

                    <v-col v-if="editflag" cols="12" xs="6" sm="6" md="4" lg="4">
                      <div>Role(s)</div>
                      <div>{{ editedItem.roles }}</div>
                    </v-col>
                    <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                      <v-select v-model="newroles" multiple :items="allRole" label="New Role(s)"> </v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" v-if="!spinner" text @click="update">Save</v-btn>
                <Spinner v-if="spinner" />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row>
            <v-col cols="12" xs="4" sm="4" md="4" lg="4">
              <h4>PFRatio</h4>
              {{ item.pfratio }}
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <h4>Roles</h4>
              {{ item.roles }}
            </v-col>
          </v-row>
        </td>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click.stop="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <div><h2 class="blue">No data Found- Please add Users</h2></div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
//import "firebase/auth";
import { auth, emailRef, usersRef } from "@/fb"
import { emailMixin } from "@/mixins/emailMixin.js"
import { formMixin } from "@/mixins/formMixin.js"
import Snackbar from "@/components/common/snackbar.vue"
import Alertmsg from "@/components/common/Alertmsg.vue"
import Spinner from "@/components/common/spinner.vue"

export default {
  name: "Regitser",
  mixins: [emailMixin, formMixin],
  components: { Snackbar, Alertmsg, Spinner },
  data: () => ({
    valid: true,
    show1: false,
    dialog: false,
    password: "",
    name: "",
    mgrNames: [],
    users: [],
    mgrName: "",
    domain: "",
    newdomain: "",
    superadminemail: "",
    hradminemail: "",
    sysadminemail: "",
    pfratio: "",

    singleExpand: false,
    expanded: [],
    expandflag: true,

    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 4) || "Name should be atleast 4 characters",
      (v) => /^[A-Za-z\s]+$/.test(v) || "Only Alphabets are allowed",
    ],
    email: null,
    emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    numberinput: [(v) => !!v || "Input is required", (v) => /^[0-9\s]+$/.test(v) || "Only Numbers  allowed"],

    select: null,
    allRole: ["superadmin", "sysadmin", "hradmin", "employee", "recruitment", "readuser"],
    newroles: [],
    headers: [
      {
        text: "Domain",
        align: "start",
        sortable: true,
        value: "domain",
      },
      { text: "Super-Admin", value: "superadminemail", align: "center" },
      { text: "HR-Admin", value: "hradminemail", align: "center" },
      { text: "Sys-Admin", value: "sysadminemail", align: "center" }, //pfratio
      { text: "PFRatio", value: "pfratio", align: "d-none" },
      { text: "Roles", value: "roles", align: "d-none" },

      { text: "Users", value: "users", align: "center" },

      { text: "Actions", value: "actions", align: "left", sortable: false },
    ],
    status: null,
    checkbox: false,
    lazy: false,
    error: "",
    notloaded: true,

    mailmsg: {
      to: "",
      subject: "",
      message: "",
    },
    viewflag: false,

    editedIndex: -1,
    editedItem: {
      domain: "",
      hradminemail: "",
      superadminemail: "",
      sysadminemail: "",
      roles: [],
      users: "",
      pfratio: "",
      modifiedat: "",
    },

    defaultItem: {},

    alertflag: false,
    alertmsg: "",
    snackbartext: "",
    snackbar: false,
    spinner: false,

    search: "",
    editflag: false,
    userdomains: [],
    roles: "",
  }), // end of data
  async created() {
    this.roles = await this.$store.state.userProfile

    console.log("Register crated", this.roles)
    if (this.roles) {
      if (this.roles.role.superadmin && (this.roles.domain = "venzo")) {
        this.domain = this.roles.domain
        this.defaultItem = Object.assign({}, this.editedItem)
        this.getdomainlist()
      } else {
        alert("Not authorized to access this page , kindly contact support")
        this.$router.replace({ name: this.roles.menufrom })
      }
    } else {
      alert("Not authorized to access this page , kindly contact support")
      this.$router.replace({ name: "/login" })
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Domain" : "Edit Domain"
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  methods: {
    async getdomainlist() {
      ;(this.userdomains = []), (this.notloaded = true)
      console.log("domainmgt domain name is ", this.domain)

      var queryresult1 = await emailRef.get()
      for (var i = 0; i < queryresult1.docs.length; ++i) {
        var queryrecord = queryresult1.docs[i].data()
        var tmpdomain = queryrecord.domain

        var queryresult2 = await usersRef.where("domain", "==", tmpdomain).get()
        await this.userdomains.push({
          uid: queryresult1.docs[i].id,
          hradminemail: queryrecord.hradminemail,
          superadminemail: queryrecord.superadminemail,
          sysadminemail: queryrecord.sysadminemail,
          roles: queryrecord.roles,
          domain: queryrecord.domain,
          users: queryresult2.docs.length,
          pfratio: queryrecord.pfratio,
        })
      }
      this.notloaded = false
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    editItem(item) {
      console.log("inside editItem")
      this.editedIndex = this.userdomains.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editflag = true
      this.dialog = true
      this.snackbar = false
    },

    async deleteItem(item) {
      const index = this.userdomains.indexOf(item)
      const deletemsg = "Are you sure you want to delete this user?" + item.domain

      const option = await this.deleteFromFb(deletemsg)
      console.log("inside deleteItem", option, index, item.uid)

      if (option) {
        try {
          emailRef.doc(item.uid).delete()
          this.users.splice(index, 1)
        } catch (error) {
          console.log("Error while delete :", error)
        }
      }
    },

    close() {
      this.dialog = false
      this.editflag = false
      this.spinner = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    reset() {
      this.$refs.form.reset()
    },
    update() {
      //alert("inside the update function")
      this.spinner = true
      console.log("inside update funcito-spinner", this.spinner)
      //  console.log("inside the update function", this.editedItem)
      this.viewflag = false
      this.editflag = false
      if (this.newroles) {
        this.editedItem.roles = this.newroles
        this.newroles = []
      }

      if (this.editedIndex > -1) {
        Object.assign(this.userdomains[this.editedIndex], this.editedItem)
        console.log("inside save item ", this.editedItem)
        this.editedItem.modifiedat = new Date()
        try {
          emailRef.doc(this.editedItem.uid).set({
            ...this.editedItem,
          })
          this.snackbar = true
          this.snackbartext = "Data updated successfully"
          this.spinner = false
          // alert("updated successfully")
        } catch (error) {
          console.log("Error while update :", error)
        }
      } else {
        this.Register()
      }
      //this.initialize();
      this.close()
    },

    async Register() {
      var queryresult1 = ""
      var queryresult2 = ""

      console.log("inside Register edititem values", this.editedItem)
      this.newdomain = this.editedItem.domain.trim()
      this.hradminemail = this.editedItem.hradminemail.trim()
      this.superadminemail = this.editedItem.superadminemail.trim()
      this.sysadminemail = this.editedItem.sysadminemail.trim()
      this.newroles = this.editedItem.roles
      this.pfratio = this.editedItem.pfratio

      console.log(
        "inside register",
        this.domain,
        this.hradminemail,
        this.superadminemail,
        this.sysadminemail,
        this.newroles
      )

      queryresult1 = await emailRef.where("domain", "==", this.newdomain).get()
      queryresult2 = await usersRef.where("email", "==", this.superadminemail).get()

      if (queryresult1.docs.length > 0) {
        //alert("Domain is already exisits")
        this.alertflag = true
        this.alertmsg = "Domain is already exisits"
        this.spinner = false
      }
      if (queryresult2.docs.length > 0) {
        // alert("superadminemail is already exisits in users role")
        this.alertflag = true
        this.alertmsg = "superadminemail is already exisits in users role"
        this.spinner = false
      } else {
        var tmppassword = Math.floor(100000 + Math.random() * 900000)
        this.password = tmppassword.toString()

        auth
          .createUserWithEmailAndPassword(this.superadminemail, this.password)
          .then(() => {
            try {
              emailRef.add({
                domain: this.newdomain,
                hradminemail: this.hradminemail,
                superadminemail: this.superadminemail,
                sysadminemail: this.sysadminemail,
                roles: this.newroles,
                pfratio: this.pfratio,
                createdat: new Date(),
              })
              usersRef.add({
                empid: "",
                email: this.superadminemail,
                status: "newuser",
                role: "superadmin",
                name: "",
                mgrName: "",
                domain: this.newdomain,
                tmppassword: this.password,
              })
              console.log("empRecord added successfully")
              this.newroles = []
              this.getdomainlist()
              this.mailfun()
              this.resetValidation()
              //mgrName,name,newroles,status

              this.snackbar = true
              this.snackbartext = "New Domain created successfully"
              this.spinner = false
            } catch (error) {
              // end of try
              console.log("Error while add :", error)
              this.alertflag = true
              ;(this.alertmsg = "Error while add new domain :"), error
              this.spinner = false
            }
          })
          .catch((err) => console.log("Error in createUserWithEmailAndPassword", err, this.superadminemail))
      } // end of else.
    },
    mailfun() {
      this.mailmsg.to = this.superadminemail
      this.mailmsg.subject = "Welcome to" + this.newdomain + "new Domain id"
      this.mailmsg.message =
        "Hi, your domain id is: " +
        this.newdomain +
        ", please login in to " +
        this.newdomain +
        " portal and update the details" +
        "the user name is your email address and password is:" +
        this.password
      console.log(this.mailmsg)
      this.sendEmail(this.mailmsg)
    },
  },
}
</script>
